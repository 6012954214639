import { useEffect } from "react";
import { SetState } from "../../../../types";
import { Data } from "../types";
import CreatorDialogTemplate from "./CreatorDialogTemplate";

import { defaultOptionImages } from "../../../../assets/images/userSite/gameboard/defaultImages";

type Props = {
  data: Data;
  setData: SetState<Data>;
  setCurrentStepNum: SetState<number>;
};

const taskImages = require.context(
  "../../../../assets/images/userSite/gameboard/options",
  true
) as any;

const OptionsIconsStep = ({ data, setData, setCurrentStepNum }: Props) => {
  useEffect(() => {
    if (data.taskOption.value[0] === "message") {
      if (data.optionsIcons.value[0] === "skipToNextStep") {
        setData((prev) => {
          return {
            ...prev,
            optionsIcons: {
              ...prev.optionsIcons,
              value: [],
            },
          };
        });
        setCurrentStepNum((prev) => prev - 1);
      } else {
        setData((prev) => {
          return {
            ...prev,
            optionsIcons: {
              ...prev.optionsIcons,
              value: ["skipToNextStep"],
            },
          };
        });
        setCurrentStepNum((prev) => prev + 1);
      }
    }
  }, []);
  if (data.taskOption.value[0] === "image") {
    return (
      <CreatorDialogTemplate
        name="optionsIcons"
        mainText="Select Your Mission Option Icon"
        subText="Select up to 10 images"
        data={data}
        setData={setData}
        imgBank={taskImages}
        filterImgs={defaultOptionImages}
      />
    );
  }
};

export default OptionsIconsStep;
