import Cookies from "js-cookie";
import { useQueryClient } from '@tanstack/react-query';

import Loading from "../../../../Components/loading/Loading";
import { MissionsTable } from "../../../Components/missionTable/missionsTable";

import styles from "../userPage.module.scss";
import { RenderableContent, SetState } from "../../../../types";
// Add to class future update
// import SelectInput from "../../../Components/selectInput/SelectInput";
// import SelectInputOption from "../../../Components/selectInput/components/SelectInputOption";
import ActionSuccessStatus from "../../../Components/actionSuccessStatus/ActionSuccessStatus";
import { DbUsers, TeacherClass, UserInfo } from "../types";

import UseHandleGetAllStudents from "../hooks/UseHandleGetAllStudents";
import ApiService from "../../../../Services/apiServiceT";

// Add to class future update
// type teacherClassObj = {
//   _id: string;
//   name: string;
//   studentIds: string[];
// };

type Props = {
    setOpenDialog: SetState<{ open: boolean; content: RenderableContent }>;
    setUsersInfo: SetState<UserInfo[]>;
    usersInfoId: string[];
    // Add to class future update
    // teacherClasses: teacherClassObj[];
    // setTeacherClasses: SetState<TeacherClass[]>;
};

const tableSetup = [
    { accessorKey: "name", header: "Name" },
    { accessorKey: "grade", header: "Grade" },
    { accessorKey: "username", header: "Username" }
];

const ExistingUser = ({
    setOpenDialog,
    setUsersInfo,
    usersInfoId,
    // Add to class future update
    // teacherClasses,
}: // Add to class future update
    // setTeacherClasses,
    Props) => {
    const queryClient = useQueryClient();
    const apiService = new ApiService(true);
    let { data: existingUsers, isLoading: isLoading, refetch } = UseHandleGetAllStudents();

    // Add to class future update
    // const [teacherClassErr, setTeacherClassErr] = useState<boolean>(false);    
    // const [addToClass, setAddToClass] = useState<{ value: string; new: boolean }>(
    //   { value: "", new: false }
    // );

    const handleAddUsers = async (newStudentIds: string[]) => {
        //e.preventDefault();

        // Add to class future update
        // if (addToClass.value === "" || addToClass.value === "choose") {
        //   setTeacherClassErr(true);
        //   return;
        // }

        const userCookie = Cookies.get("user");
        const userObject = userCookie ? JSON.parse(userCookie) : null;
        let teacherUsername = userObject?.username ? userObject.username : "";
        const schoolName = userObject?.organization
            ? userObject.organization.replace(" ", "")
            : "school";


        setOpenDialog({
            open: true,
            content: <ActionSuccessStatus message={`Loading.... \n Please Wait`} />,
        });

        // API call to patch
        const [response, error] = await apiService.patch({
            path: "/students",
            dataType: "admin",
            data: {
                newStudentIds,
            }
        });

        // Update the cached data
        let message: RenderableContent = <></>;
        if (response) {
            message = (
                <>
                    Success! <br></br> Student(s) are now connected to your account.
                </>
            );

            // Update the cache with the new data
            await queryClient.setQueryData(['existingUsers'], (oldData: DbUsers[]) => {
                const newData = oldData.filter(student => !newStudentIds.includes(student._id));
                return newData;
            });
            // refetch();

            // Update main Users table
            // Take students who were added to the roster
            const newUserInfos: UserInfo[] = existingUsers?.filter(student => newStudentIds.includes(student._id)).map(student => {
                return {
                    id: schoolName + "_" + student._id,
                    fname: student.name.split(" ")[0],
                    lname: student.name.split(" ")[1],
                    last_mission: student.last_mission,
                    date: student.date,
                }
            }) ?? [];
            setUsersInfo((prev) => {
                return [
                    ...prev,
                    ...newUserInfos
                ];
            });
        }
        else {
            message = (
                <>
                    Something went wrong. <br></br> Please try again.
                </>
            );
        }
        // const content = await handleAddUserHook(
        //     capitalizeWords(fname),
        //     capitalizeWords(lname),
        //     username,
        //     schoolName,
        //     password,
        //     parentEmail,
        //     teacherUsername,
        //     // Add to class future update
        //     // addToClass,
        //     grade,
        //     setOpenDialog,
        //     setUsersInfo
        //     // Add to class future update
        //     // setTeacherClasses
        // );
        // if (!content) return;
        setOpenDialog({
            open: true,
            content: <ActionSuccessStatus
                message={message}
                customContent={null}
                closeHandler={() => setOpenDialog({ open: false, content: null })}
            />,
        });
    }

    // Add to class future update
    // const selectInputOptions = [
    //   <SelectInputOption
    //     key={"choose"}
    //     option={{ name: "Select A Class", value: "choose" }}
    //   />,
    //   teacherClasses.map((teacherClass) => {
    //     return (
    //       <SelectInputOption
    //         key={teacherClass._id}
    //         option={{ name: teacherClass.name, value: teacherClass._id }}
    //       />
    //     );
    //   }),
    //   <SelectInputOption
    //     key={"newClass"}
    //     option={{ name: "New Class", value: "newInput" }}
    //   />,
    // ];

    if (!isLoading) {
        existingUsers = existingUsers?.filter(user => !usersInfoId.includes(user._id))
    }

    return (
        <div className={styles.mainContainer} style={{}}>
            <Loading isLoading={isLoading} />
            {!isLoading &&
                <>
                    <MissionsTable
                        data={existingUsers}
                        tableSetup={tableSetup}
                        title="Add Existing Student(s)"
                        checkbox
                        customBtn={
                            {
                                text: "Add",
                                clickHandler: handleAddUsers
                            }
                        }
                        tooltipSelected={{ text: "Add", clickHandler: handleAddUsers }}
                    />
                </>
            }

        </div>
    );
};

export default ExistingUser;