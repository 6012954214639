import ChangePassLogic from "../../../Components/ChangePassLogic";
import MuiTabs from "../../../Components/MuiTabs/MuiTabs";
import AdminTemplate from "../../Components/adminTemplate/AdminTemplate";
import ChangePass from "./components/ChangePass";
import Profile from "./components/Profile";

import styles from "./adminProfile.module.scss";

const AdminProfilePage = () => {
  const mainContent = (
    <div className={styles.mainContainer}>
      <MuiTabs
        tabs={["Basic Info", "Change Password"]}
        items={[
          <Profile />,
          <ChangePassLogic
            form={
              <ChangePass
                currentPass=""
                setCurrentPass={() => {}}
                newPassVali={{ pattern: "", message: "" }}
                newPass=""
                setNewPass={() => {}}
                confirmPass=""
                setConfirmPass={() => {}}
                handleChangePassword={() => {}}
              />
            }
          />,
        ]}
      />
    </div>
  );
  return <AdminTemplate pageContent={mainContent} />;
};

export default AdminProfilePage;
