import { useQuery } from '@tanstack/react-query'

import ApiService from "../../../../Services/apiServiceT";

import { DbUsers } from '../types';

const UseHandleGetAllStudents = () => {
    const apiService = new ApiService(true);

    const fetchUserData = async () => {
        let users: DbUsers[] = [];

        const [response, error] = await apiService.get({
            path: "/students",
            dataType: "admin",
        });

        if (response) {
            users = response.data as DbUsers[];
        }

        return users;
    };

    return useQuery({
        queryKey: ['existingUsers'],
        queryFn: fetchUserData,
        staleTime: 0,
    });


};

export default UseHandleGetAllStudents;
