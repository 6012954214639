import { useCallback } from "react";
import ApiService from "../../../../Services/apiServiceT";
import ActionSuccessStatus from "../../../Components/actionSuccessStatus/ActionSuccessStatus";
import { RenderableContent, SetState } from "../../../../types";
import InfoTable from "../../../Components/infoTable/InfoTable";

import styles from "../userPage.module.scss";
import { TeacherClass, UserInfo } from "../types";

type AddUserResp = {
  status: string;
  studentId: string;
  studentUsername: string;
  // Add to class future updateF
  // newClassId: string;
};

const useHandleAddUserHook = () => {
  const apiService = new ApiService();
  const handleAddUserHook = useCallback(
    async (
      fname: string,
      lname: string,
      username: string,
      schoolName: string,
      password: string,
      parentEmail: string,
      teacherUsername: string,
      // Add to class future update
      // addToClass: { value: string; new: boolean },
      grade: string,
      setOpenDialog: SetState<{ open: boolean; content: RenderableContent }>,
      setUsersInfo: SetState<UserInfo[]>
      // Add to class future update
      // setTeacherClasses: SetState<TeacherClass[]>
    ) => {
      let message: RenderableContent = <></>;
      let customContent = null;

      const tableInfo = [
        { key: "fname", label: "First Name", value: fname },
        { key: "lname", label: "Last Name", value: lname },
      ];
      try {
        const [addUserResp, err] = await apiService.post({
          path: "/add_student",
          dataType: "admin",
          data: {
            fname,
            lname,
            username,
            schoolName,
            password,
            parentEmail,
            teacherUsername,
            // Add to class future update
            // addToClass,
            grade,
          },
        });
        console.log(addUserResp);
        if (addUserResp) {
          const data = addUserResp.data as AddUserResp;
          tableInfo.push(
            { key: "username", label: "Username", value: data.studentUsername },
            { key: "password", label: "Password", value: password },
            { key: "grade", label: "Grade", value: grade },
            { key: "parentEmail", label: "Parent's Email", value: parentEmail }
          );
          setUsersInfo((prev) => {
            return [
              ...prev,
              {
                id: data.studentId,
                fname: fname,
                lname: lname,
                last_mission: "No Missions Completed",
                date: "",
              },
            ];
          });
          // Add to class future update
          // if (addToClass.new) {
          //   setTeacherClasses((prev) => {
          //     return [
          //       ...prev,
          //       {
          //         _id: data.newClassId,
          //         name: addToClass.value,
          //         studentIds: [data.studentId],
          //       },
          //     ];
          //   });
          // }
          message = (
            <>
              Completed <br></br> Student Successfully <br></br> Added
            </>
          );
          customContent = (
            <div className={styles.infoTableContainer}>
              <InfoTable
                tableTitle="Created Account"
                tableInfo={tableInfo}
                infoTableCustomClass={styles.infoTableCustomClass}
                printable
              />
            </div>
          );
        } else if (err.response.status === 409) {
          message = (
            <>
              There is already an account associated <br></br> with this email
              address.
            </>
          );
        } else {
          message = (
            <>
              Something went wrong. <br></br> Please try again.
            </>
          );
        }
      } catch (error) {
        console.log(error);
        message = (
          <>
            Something went wrong. <br></br> Please try again.
          </>
        );
      }

      return (
        <ActionSuccessStatus
          message={message}
          customContent={customContent}
          closeHandler={() => setOpenDialog({ open: false, content: null })}
        />
      );
    },
    []
  );
  return handleAddUserHook;
};

export default useHandleAddUserHook;
