import React from 'react';
import { Link } from "react-router-dom";
import { Button } from '../../../../Components/button/button';

import styles from '../homePage.module.scss';

import why1 from '../../../../assets/images/website/why1.png';
import why2 from '../../../../assets/images/website/why2.png';
import why3 from '../../../../assets/images/website/why3.png';
import arrow from '../../../../assets/images/website/arrow2.png';

export const WhySection = (props) => {
  return (
    <div className={styles.whySection}>
      <h1>Why Super Me club?</h1>
      <div className={styles.whySectionContainer}>
        <div className={`${styles.whySectionCol} ${styles.whySectionRow}`}>
          <div className={`col-lg-6 col-md-12 col-sm-12`}>
            <p>Schools</p>
            <h2>
              “The program has really inspired our students to strengthen
              their healthy habits.”
            </h2>
            <p>Chani Okonov / Mazal Day School Director</p>
            <div>
              Our students have fallen in love with the Super Me heroes.
              Their excitement to move up in level is palpable. You can
              overhear them sharing with each other what they've been
              working on.{" "}
              <span class="fw-bold">
                {" "}
                The Super Me program has really inspired our students to
                strengthen their healthy habits!
              </span>
            </div>
            <Link to={"/sign-up/?_form=school"}>
              <Button
                style={{
                  marginTop: '10px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  Schools, sign up
                  <img
                    src={arrow}
                    className={styles.arrow}
                    alt=""
                  />
                </div>
              </Button>
            </Link>
          </div>
          <div className={`col-lg-6 col-md-12 col-sm-12`}>
            <img src={why1} alt={'why1'} className={styles.gameBoard} />
          </div>
        </div>
        <div className={`${styles.whySectionColRev} ${styles.whySectionRow}`}>
          <div className={`col-lg-6 col-md-12 col-sm-12`}>
            <img src={why2} alt={'why2'} className={styles.achievement} />
          </div>
          <div className={`col-lg-6 col-md-12 col-sm-12`}>
            <p>Teachers</p>
            <h2>“My students loved the Super Me Club”</h2>
            <p>Menucha S / Fourth Grade Teacher</p>
            <div> Our platform grants teachers visibility into their student's Super Me tasks to help stop unhealthy habits and encourages healthy ones right there in the classroom.
            </div>
            <Link to={"/sign-up/?_form=teacher"}>
              <Button
                style={{ marginTop: '10px' }}
              >
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  Teachers, sign up
                  <img
                    src={arrow}
                    className={styles.arrow}
                    alt=""
                  />
                </div>
              </Button>
            </Link>
          </div>
        </div>
        <div className={`${styles.whySectionCol} ${styles.whySectionRow}`}>
          <div className={`col-lg-6 col-md-12 col-sm-12`}>
            <p>Parents</p>
            <h2>“I couldn't believe my eyes, seeing my daughter
              drinking water”
            </h2>
            <p>Simona Rotman / Mother of Hanna, age 6</p>
            <div>
              I couldn't believe my eyes, seeing my daughter drinking
              water. It was juice only for her. Super Me really got her
              excited and motivated to go out of her comfort zone and
              start changing her habits.
            </div>
            <Link to={"/sign-up/?_form=parent"}>
              <Button
                style={{ marginTop: '10px' }}
              >
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  Parents, sign up
                  <img
                    src={arrow}
                    className={styles.arrow}
                    alt=""
                  />
                </div>
              </Button>
            </Link>
          </div>
          <div className={`col-lg-6 col-md-12 col-sm-12`}>
            <img src={why3} alt={'why3'} className={styles.quiz} />
          </div>
        </div>
      </div>
    </div>
  );
};
