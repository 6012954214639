import { React, useState, useEffect, useContext } from "react";

import AdminTemplate from "../../../Components/adminTemplate/AdminTemplate";
import { MissionsTable } from "../../../Components/missionTable/missionsTable";
import { InfoContext } from "../AdminCampaignsPage";

import ApiService from "../../../../Services/apiService";
import { capitalizeWords, shortNumFormat } from "../../../../Util/utils";

const campaignsTableSetup = [
  { accessorKey: "name", header: "Name" },
  { accessorKey: "studentCount", header: "Students" },
  { accessorKey: "progress", header: "Progress" },
  { accessorKey: "goalCompleted", header: "Goal" },
];

const SelectCampaign = () => {
  const [campaigns, setCampaigns] = useState([]);
  const { setCurrentCampaign, gradeTableUpdater } = useContext(InfoContext);
  const apiService = new ApiService();

  const setCampaignHandler = (id) => {
    let campaign = campaigns.find((campaign) => campaign._id === id);
    campaign.progress = campaign.rawData.progress.value;
    campaign.goalCompleted = campaign.rawData.goalCompleted.value;
    const goalType = capitalizeWords(campaign.goalType);
    let newGradeTable = [];
    campaign.participants = [];
    const fetchData = async () => {
      try {
        const [studentsInfoResp, err] = await apiService.get({
          path: "/campaign_participation_status",
          dataType: "admin",
          data: {
            db: campaign.db,
            participatingStudentIds: campaign.studentIds,
            info: ["name", "grade"],
            schoolIds: campaign.schoolIds,
          },
        });

        if (studentsInfoResp) {
          for (const student of studentsInfoResp.data.participants) {
            newGradeTable = gradeTableUpdater(
              campaign._id,
              goalType,
              student,
              newGradeTable,
              campaign.gradeTotals
            );

            let studentProgress =
              campaign.studentTotals && campaign.studentTotals[student._id]
                ? campaign.studentTotals[student._id]
                : 0;
            student.rawData = {
              studentProgress: {
                value: studentProgress,
                suffix: goalType,
              },
            };
            campaign.participants.push(student);
          }

          campaign.gradeTable = newGradeTable;
          campaign.noneParticipants = studentsInfoResp.data.noneParticipants;
          campaign.crossSchoolData = studentsInfoResp.data.crossSchoolData && [
            {
              schoolName: campaign.schoolName,
              studentCount: campaign.participants.length,
              progress: `${shortNumFormat(campaign.progress)} ${
                campaign.goalType
              }`,
            },
            ...studentsInfoResp.data.crossSchoolData,
          ];

          campaign.schoolTotals = campaign.crossSchoolData?.reduce(
            (obj, key) => {
              const progress = key.progress.split(" ")[0];
              obj[key.schoolName] = progress;
              return obj;
            },
            {}
          );

          return campaign;
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData().then((newCurrentCampaign) => {
      if (newCurrentCampaign) setCurrentCampaign(newCurrentCampaign);
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [campaignsResp, err] = await apiService.get({
          path: "/get_campaigns",
          dataType: "admin",
        });
        if (campaignsResp) {

          setCampaigns(() =>
            campaignsResp.data.campaigns.map((campaign) => {
              const goalType = capitalizeWords(campaign.goalType);
              const studentCount = campaign.studentIds.length;
              const rawData = {
                progress: {
                  value: campaign.progress,
                  suffix: goalType,
                },
                goalCompleted: {
                  value:
                    campaign.goalCompleted > 0
                      ? campaign.goalCompleted
                      : `Do All The ${goalType} You Can!`,
                  suffix: campaign.goalCompleted > 0 ? goalType : "",
                },
              };
              return {
                ...campaign,
                studentCount,
                rawData,
              };
            })
          );
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const pageContent = (
    <MissionsTable
      title="Campaigns"
      tableSetup={campaignsTableSetup}
      data={campaigns}
      clickHandler={setCampaignHandler}
    />
  );

  return (
    <>
      <AdminTemplate pageContent={pageContent} />
    </>
  );
};

export default SelectCampaign;
