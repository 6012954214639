import { React, useState, useEffect, cloneElement } from "react";
import { useNavigate } from "react-router";

import DialogTemplate from "./DialogTemplate";

import ApiService from "../Services/apiService";
import { validation } from "../Util/Validation";

const ChangePassLogic = ({ form }) => {
  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [newPassVali, setNewPassVali] = useState("");
  const [dialog, setDialog] = useState(null);

  const navigate = useNavigate();
  const apiService = new ApiService();

  const handleChangePassword = async (e) => {
    e.preventDefault();
    try {
      const [passwordResp, passwordError] = await apiService.post({
        path: "/update_password",
        dataType: "user",
        data: {
          currentPass: currentPass,
          newPass: newPass,
        },
      });

      if (!passwordError) {
        //show a modal confirmation
        setDialog({
          mainText: "Password Updated",
          subText: "Your password has been updated successfully",
          duration: "1000ms",
          closeHandler: handleCloseDialog,
        });
        setCurrentPass("")
        setNewPass("")
        setConfirmPass("")
      } else {
        setDialog({
          mainText: "Password Not Update!",
          subText: "Your password has not been updated!\nPlease try again!",
          duration: "1000ms",
          closeHandler: handleCloseDialog,
        });

        setTimeout(() => {
          setDialog(null);
        }, 3000);
      }
    } catch (error) {
      console.log(error);
      setDialog({
        mainText: "Something went wrong!",
        subText:
          "Please try again!\nIf this issue continues please reach out to Support",
        duration: "1000ms",
        closeHandler: handleCloseDialog,
      });

      setTimeout(() => {
        setDialog(null);
      }, 3000);
    }
  };

  const handleCloseDialog = () => {
    setDialog(null);
  };

  useEffect(() => {
    if (currentPass === newPass) {
      setNewPassVali({
        pattern: "err",
        message: "Your new password is same as current password",
      });
    } else {
      setNewPassVali({
        pattern: validation.password.pattern,
        message: validation.password.message,
      });
    }
  }, [currentPass, newPass]);

  const modifiedProps = {
    currentPass: currentPass,
    setCurrentPass: setCurrentPass,
    newPassVali: newPassVali,
    newPass: newPass,
    setNewPass: setNewPass,
    confirmPass: confirmPass,
    setConfirmPass: setConfirmPass,
    handleChangePassword: handleChangePassword,
  };

  return (
    <>
      {dialog && <DialogTemplate {...dialog} />}
      {cloneElement(form, modifiedProps)}
    </>
  );
};

export default ChangePassLogic;
