import { useState } from "react";

import styles from "../userPage.module.scss";
import { RenderableContent, SetState } from "../../../../types";
// Add to class future update
// import SelectInput from "../../../Components/selectInput/SelectInput";
// import SelectInputOption from "../../../Components/selectInput/components/SelectInputOption";
import { TeacherClass, UserInfo } from "../types";

import ExistingUser from "./exisitingUser";
import NewUser from "./newUser";

// Add to class future update
// type teacherClassObj = {
//   _id: string;
//   name: string;
//   studentIds: string[];
// };

type Props = {
  setOpenDialog: SetState<{ open: boolean; content: RenderableContent }>;
  setUsersInfo: SetState<UserInfo[]>;
  usersInfo: UserInfo[];
  // Add to class future update
  // teacherClasses: teacherClassObj[];
  // setTeacherClasses: SetState<TeacherClass[]>;
};

const AddUser = ({
  setOpenDialog,
  setUsersInfo,
  usersInfo,
  // Add to class future update
  // teacherClasses,
}: // Add to class future update
  // setTeacherClasses,
  Props) => {
  const [formType, setFormType] = useState('new');

  const formsList: any = {
    existing: {
      lable: "Existing Student",
      form: <ExistingUser
        setOpenDialog={setOpenDialog}
        setUsersInfo={setUsersInfo}
        usersInfoId={usersInfo.map(user => user.id.split('_').at(-1) ?? "")}
      // Add to class future update
      // teacherClasses={teacherClasses}
      // setTeacherClasses={setTeacherClasses}
      />,
    },
    new: {
      lable: "New Student",
      form: <NewUser
        setUsersInfo={setUsersInfo}
        setOpenDialog={setOpenDialog}
      // Add to class future update
      // teacherClasses={teacherClasses}
      // setTeacherClasses={setTeacherClasses}
      />,
    },
  };

  const formChangerHandler = (tab: string) => {
    setFormType(tab);
  };

  return (
    <div style={{ width: '100%' }}>
      <div className="d-flex" style={{ justifyContent: 'space-evenly' }}>
        <div className="text-center">
          <button
            className={styles.basic_btnInv}
            style={{ padding: '5px 10px' }}
            onClick={() => {
              formChangerHandler('new');
            }
            }
          >
            New Student
          </button>
        </div>
        <div className="text-center">
          <button
            className={styles.basic_btnInv}
            style={{ padding: '5px 10px' }}
            onClick={() => {
              formChangerHandler('existing');
            }
            }
          >
            Existing Students
          </button>
        </div>
      </div>
      <div style={{ paddingRight: '10px', overflow: 'auto' }}>
        {formsList[formType]["form"]}
      </div>
    </div>
  );
};

export default AddUser;
