import React, { useEffect, useRef, useState } from "react";

import { formatDateString } from "../../../../Util/utils";

import styles from "../infoTable.module.scss";

const EditableField = ({
  originalValue,
  suffix,
  route,
  path,
  onSaveHandler,
  noneTableContingencies,
  type,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(originalValue);
  const [error, setError] = useState("");
  const originalValueRef = useRef(originalValue);
  const inputTags = {
    className: `text-center ${styles.blue_border} ${styles.inputField}`,
    text: "text",
  };

  useEffect(() => {
    setValue(originalValue);
    originalValueRef.current = originalValue;
  }, [originalValue]);

  if (type === "date") {
    inputTags.placeholder = "MM/DD/YYYY";
    inputTags.pattern = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
    inputTags.errormsg = "Please enter the date in MM/DD/YYYY format";
  } else if (type === "number") {
    inputTags.pattern = /^[0-9,]+$/;
    inputTags.errormsg = "Please enter a valid number";
    inputTags.value = value.toLocaleString();
  } else {
    inputTags.value = value;
  }

  const onChange = (e) => {
    setValue(() => {
      let eValue = e.target.value;
      if (type === "number") {
        eValue = eValue.replace(/,/g, "");
        if (eValue.trim() === "") {
          eValue = "";
        } else if (!isNaN(eValue)) {
          eValue = parseFloat(eValue).toLocaleString();
        }
      }
      return eValue;
    });
  };

  const validateDate = (value) => {
    let valueToTest = value;
    if (type === "number") {
      valueToTest = +value;
      if (isNaN(valueToTest)) valueToTest = +value.replaceAll(",", "");
    }
    return inputTags.pattern.test(valueToTest);
  };

  const onSave = () => {
    let newValue = value;
    if (
      inputTags.pattern &&
      !validateDate(value) &&
      value !== originalValueRef.current &&
      value !== ""
    ) {
      setError(inputTags.errormsg);
      return;
    }
    if (value !== originalValueRef.current) {
      if (type === "date") {
        const [month, day, year] = value.split("/");
        const date = new Date(year, month - 1, day);
        const oldValue = new Date(originalValueRef.current);
        newValue = date.getTime();
        newValue = newValue - oldValue.getTime();
        const displayValue = formatDateString(date);
        setValue(displayValue);
        originalValueRef.current = displayValue;
      } else if (type === "number") {
        const rawValue = +value.replaceAll(",", "");
        newValue = originalValueRef.current;
        if (isNaN(newValue)) newValue = +newValue.replaceAll(",", "");
        newValue = rawValue - newValue;
        originalValueRef.current = rawValue.toLocaleString();
        setValue(rawValue.toLocaleString());
      }
      onSaveHandler({ route, path, newValue, noneTableContingencies });
    }

    setIsEditing(false);
    setError("");
  };

  const toggleEditing = () => {
    if (isEditing) {
      onSave();
    } else {
      setIsEditing(true);
    }
  };

  return (
    <>
      {isEditing ? (
        <div style={{ scale: "1" }} className={styles.dataContainer}>
          <input {...inputTags} onChange={onChange} />
          {error && (
            <div className={styles.errorContainer}>
              <div className={styles.error}>{error}</div>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.data}>
          {suffix ? `${value} ${suffix}` : value}
        </div>
      )}
      <button className={styles.edit} onClick={toggleEditing}>
        {isEditing ? "Save" : "Edit"}
      </button>
    </>
  );
};

export default EditableField;
