import { RenderableContent, SetState } from "../../../../types";
import InfoTable from "../../../Components/infoTable/InfoTable";
import styles from "../creatorPage.module.scss";
import { CreatorGameboardConfig, Data } from "../types";
import CreatorDialogTemplate from "./CreatorDialogTemplate";

type Props = {
  data: Data;
  setData: SetState<Data>;
  gameboardConfigs: CreatorGameboardConfig[];
};

type TableInfo = {
  label: string | boolean;
  value: string | number;
}[];

const CreateMissionSummary = ({
  data,
  setData,
  gameboardConfigs,
}: Props): RenderableContent => {
  const dataKeys = Object.keys(data);
  const tableLabels: Record<keyof Data, boolean | string> = {
    category: "Category",
    task: "Task",
    taskOption: "Task Option Type",
    taskCompleted: false,
    addToGame: "Add To Game",
    createMissionSummary: false,
  };

  const checkfieldSet = (key: keyof Data): boolean => {
    if (
      !data[key].value ||
      data[key].value[0] === "choose" ||
      data[key].value[0] === "" ||
      !tableLabels[key]
    ) {
      return false;
    } else {
      return true;
    }
  };

  const tableInfo: TableInfo = dataKeys
    .map((key) => {
      let value = data[key as keyof Data]?.value[0];
      if (!checkfieldSet(key as keyof Data)) return null;
      if (key === "category" && value === "new")
        value = data.category.inputField?.value
          ? data.category.inputField?.value
          : "";
      if (key === "taskOption") {
        if (value === "message") value = "Fill-In";
        if (value === "image") value = "Multiple Choice";
      }
      if (key === "addToGame") {
        const gamboard = gameboardConfigs.find((game) => game._id === value);
        if (gamboard) value = gamboard.name!;
        if (data.createProcess.value[0] === "quickCreate") {
          value = "All"
        }
      }
      return {
        label: tableLabels[key as keyof Data],
        value: value,
      };
    })
    .filter((item) => item !== null);
  const additionaltableFields = [
    { label: "Reward Type", value: data.taskCompleted.rewardType?.value! },
    { label: "Reward Amount", value: data.taskCompleted.rewardAmount?.value! },
    { label: "Max Allowed", value: data.taskCompleted.maxAllowed?.value! },
    { label: "Reset Period", value: data.taskCompleted.resetPeriod?.value! },
  ];
  additionaltableFields.forEach((field) => tableInfo.push(field));

  const subText =
    "Review and confirm the mission details before creating the mission.";

  const mainContent = (
    <div className={styles.infoTableContainer}>
      <InfoTable
        tableTitle=""
        route="null"
        tableInfo={tableInfo}
        onSaveHandler={null}
        handleRowClick={() => {}}
      />
    </div>
  );

  return (
    <CreatorDialogTemplate
      name="createMissionSummary"
      mainText="New Mission Summary"
      subText={subText}
      data={data}
      setData={setData}
      customFields={mainContent}
    />
  );
};

export default CreateMissionSummary;
