import { useState } from "react";

import ApiService from "../../../../Services/apiServiceT";
import { validation } from "../../../../Util/Validation";

import styles from "../adminProfile.module.scss"

type Props = {
  label: string;
  value: string;
  infoSetter: (v: string) => void;
  id: string;
  editable: boolean;
};

type UpdatingProfileResp = {
  status: number;
};

const InfoField = ({ label, value, infoSetter, id, editable }: Props) => {
  const [edit, setEdit] = useState(false);
  const [inputInvalid, setInputInvalid] = useState("");
  const [initialValue, setInitialValue] = useState(value);
  const apiService = new ApiService();
  const customValidate = {
    name: {
      pattern: /^[A-Za-z]+ ([A-Za-z]+)([A-Za-z ]+)$/,
      message: validation.name.message,
    },
    username: { ...validation.email },
    grade: { pattern: /^[0-9]([A-Za-z])*/, message: validation.grade.message },
    school: { ...validation.grade },
  };
  let pattern = customValidate[id as keyof typeof customValidate].pattern;
  let re = new RegExp(pattern);
  const handleEdit = async () => {
    setEdit((prev) => {
      if (inputInvalid === "inputInvalid") {
        return prev;
      }
      return !prev;
    });
    if (initialValue !== value && inputInvalid !== "inputInvalid") {
      try {
        let data: Record<string, string> = {};
        data[id] = value;
        const [resp, error] = await apiService.post({
          path: "/update_profile",
          data: data,
          dataType: "admin",
        });
        const updatingProfileResp = resp as UpdatingProfileResp;
        if (updatingProfileResp.status === 200) {
          setInitialValue(value);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <>
      <tr>
        <td className={`profileTable text-center ${styles.text}`}>
          {label}
        </td>
        {edit ? (
          <td className={`text-center ${styles.text}`}>
            <input
              type="text"
              className={`p-2 profileTable text-center inputBorder ${styles.text} ${inputInvalid}`}
              id="staticEmail"
              value={value}
              onChange={(event) => {
                infoSetter(event.target.value);
                if (
                  !re.test(event.target.value) ||
                  event.target.value.trim() === ""
                ) {
                  setInputInvalid("inputInvalid");
                } else {
                  setInputInvalid("");
                }
              }}
            />
          </td>
        ) : (
          <td
            className={`p-3 profileTable text-center ${styles.text}`}
          >
            {value}
          </td>
        )}

        <td
          style={editable ? { color: "#445eaa" } : { color: "transparent" }}
          className={`profileTable highlight ${styles.text}`}
          onClick={() => editable && handleEdit()}
        >
          {edit ? "Save" : "Change"}
        </td>
      </tr>
    </>
  );
};

export default InfoField;
