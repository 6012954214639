import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Authentication from "./Authentication/Authentication";

// Main Pages
import { HomePage } from "./LandingPages/Pages/HomePage/HomePage";
import BookDemoPage from "./LandingPages/Pages/BookDemoPage/BookDemoPage";
import SignUpPages from "./LandingPages/Pages/SignUpPage/SignUpPage";
import LoginPages from "./LandingPages/Pages/LoginPage/LoginPage";

// General Pages
import ContactUs from "./LandingPages/Pages/ContactUsPage/ContactUsPage";
import VerifyPage from "./LandingPages/Pages/VerifyPage/VerifyPage";
import PrivacyPage from "./LandingPages/Pages/PrivacyPage";
import TermsPage from "./LandingPages/Pages/TermsPage";
import NotFoundPage from "./LandingPages/Pages/NotFoundPage";

// User Pages
import AchievementsPage from "./UserPages/Pages/AchievementsPage/AchievementsPage";
import MissionsPage from "./UserPages/Pages/MissionsPage/MissionsPage";
import LearnPage from "./UserPages/Pages/LearnPage/LearnPage";
import UserCampaignsPage from "./UserPages/Pages/UserCampaignsPage";
import QuestPage from "./UserPages/Pages/QuestsPage/QuestPage";
import UserProfilePage from "./UserPages/Pages/UserProfilePage/UserProfilePage";
import TreasureRoadPage from './UserPages/Pages/TreasureRoadPage/TreasureRoadPage';

// Admin Pages
import AdminPanel from "./AdminPages/Pages/AdminPanelPage/AdminPanelPage";
import Users from "./AdminPages/Pages/UsersPage/UsersPage";
import UserInfo from "./AdminPages/Pages/UsersInfoPage/UserInfoPage";
import AdminCampaigns from "./AdminPages/Pages/AdminCampaignsPage/AdminCampaignsPage";
import CreatorPage from "./AdminPages/Pages/CreatorPage/CreatorPage";
import AdminProfilePage from "./AdminPages/Pages/AdminProfilePage/AdminProfilePage";

const AppRoutes = () => {

  return (
    <BrowserRouter>
      <Routes>
        {/* Public Routes */}
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/home" element={<HomePage />} />
        <Route exact path="/bookdemo" element={<BookDemoPage />} />
        <Route exact path="/privacy" element={<PrivacyPage />} />
        <Route exact path="/terms" element={<TermsPage />} />
        <Route exact path="/contactUs" element={<ContactUs />} />

        <Route
          exact
          path="/reset_password/:token"
          element={<LoginPages formType="resetPassword" />}
        />
        <Route
          exact
          path="/verify_student/:token"
          element={<VerifyPage />}
        />

        {/* Login and Sign-Up */}
        <Route
          element={
            <Authentication
              redirect={"/achievements"}
              require={{ authenticated: false, role: "any" }}
            />
          }
        >
          <Route
            exact
            path="/login"
            element={<LoginPages formType="login" />}
          />
          <Route
            exact
            path="/forgot-password"
            element={<LoginPages formType="forgotPassword" />}
          />
          <Route exact path="/sign-up" element={<SignUpPages />} />
        </Route>

        {/* Student Routes */}
        <Route
          element={
            <Authentication
              redirect={"/dashboard"}
              require={{
                authenticated: true,
                role: ["student"],
              }}
            />
          }
        >
          <Route exact path="/profile" element={<UserProfilePage />} />
          <Route exact path="/missions" element={<MissionsPage />} />
          <Route exact path="/achievements" element={<AchievementsPage />} />
          <Route exact path="/learn" element={<LearnPage />} />
          <Route exact path="/quests" element={<QuestPage />} />
          <Route exact path="/userCampaigns" element={<UserCampaignsPage />} />
          <Route exact path="/treasureRoad" element={<TreasureRoadPage /> } />
        </Route>

        {/* Admin Routes */}
        <Route
          element={
            <Authentication
              redirect={"/achievements"}
              require={{
                authenticated: true,
                role: ["parent", "teacher", "admin"],
              }}
            />
          }
        >
          <Route exact path="/dashboard" element={<AdminPanel />} />
          <Route exact path="/users" element={<Users />} />
          <Route exact path="/user/:id" element={<UserInfo />} />
          <Route exact path="/campaigns" element={<AdminCampaigns />} />
          <Route exact path="/creator" element={<CreatorPage />} />
          <Route exact path="/admin-profile" element={<AdminProfilePage/>} />
        </Route>





        <Route exact path="/404" element={<NotFoundPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
