

// const specialFunctions = {
//     _length: value => (Array.isArray(value) || typeof value === 'object' ? Object.keys(value).length : 0),
//     _count: (value, target) => {
//         const pair = target?.includes(':') ? target.split(':') : [target, null];
//         const key = pair[0];
//         const valueToMatch = pair[1];

//         if (Array.isArray(value)) {
//             return value.filter(item => (typeof item === 'object' && item.hasOwnProperty(key) && item[key] === valueToMatch)).length;
//         } else if (typeof value === 'object') {
//             return Object.values(value).filter(item => (typeof item === 'object' && item.hasOwnProperty(key) && item[key] === valueToMatch)).length;
//         }
//         return 0;
//     }
// };

const specialFunctions = {
    _length: (value, target, dateCheck) => {
        if (dateCheck) {
            value = filterByDate(value, dateCheck);
        }
        return (Array.isArray(value) || typeof value === 'object') ? Object.keys(value).length : 0;
    },
    _count: (value, target, dateCheck) => {
        if (dateCheck) {
            value = filterByDate(value, dateCheck);
        }

        const pair = target?.includes(':') ? target.split(':') : [target, null];
        const key = pair[0];
        const valueToMatch = pair[1];

        if (Array.isArray(value)) {
            return value.filter(item => (typeof item === 'object' && item.hasOwnProperty(key) && item[key] === valueToMatch)).length;
        } else if (typeof value === 'object') {
            return Object.values(value).filter(item => (typeof item === 'object' && item.hasOwnProperty(key) && item[key] === valueToMatch)).length;
        }
        return 0;
    }
};

const filterByDate = (value, dateCheck) => {
    const checkDate = new Date(dateCheck);

    if (Array.isArray(value)) {
        return value.filter(item => item.date && (new Date(item.date) >= checkDate));
    } else if (typeof value === 'object') {
        let filteredValue = {};
        for (const key in value) {
            if (value[key].date && new Date(value[key].date) >= checkDate) {
                filteredValue[key] = value[key];
            }
        }
        return filteredValue;
    }
    return value;
};

// List of all relevant operators
export const operators = [
    '+', '-', '*', '/', '%', '^', '(', ')',
    '&&', '||', '!', '===', '!==', '==', '!=',
    '++', '--', '~', 'typeof', 'void', 'delete',
    '>', '<', '>=', '<=', '>>', '<<', '>>>', ' instanceof '
];


export function splitEquationByOperators(equation) {
    let result = [];
    let currentWord = '';

    for (let i = 0; i < equation.length; i++) {
        const char = equation[i];

        // Check if the character is part of a multi-character operator
        const nextTwoChars = equation.slice(i, i + 2);
        const nextThreeChars = equation.slice(i, i + 3);

        if (operators.includes(nextTwoChars)) {
            // Process the current word before the operator
            if (currentWord.trim() !== '') {
                result.push(currentWord.trim());
                currentWord = '';
            }
            result.push(nextTwoChars);
            i += nextTwoChars.length - 1; // Skip over the operator
        } else if (operators.includes(nextThreeChars)) {
            // Process the current word before the operator
            if (currentWord.trim() !== '') {
                result.push(currentWord.trim());
                currentWord = '';
            }
            result.push(nextThreeChars);
            i += nextThreeChars.length - 1; // Skip over the operator
        } else if (operators.includes(char)) {
            // Single character operator
            if (currentWord.trim() !== '') {
                result.push(currentWord.trim());
                currentWord = '';
            }
            result.push(char);
        } else {
            // Accumulate characters to form an identifier or number
            currentWord += char;
        }
    }

    // Append the last accumulated word if any
    if (currentWord.trim() !== '') {
        result.push(currentWord.trim());
    }

    return result;
}


export function evaluateEquations(equations, variableSet, dateCheck) {
    return equations.every(equation => {

        // Split equation by its operators
        const splitEquation = splitEquationByOperators(equation);

        // Replace variable references with their actual values from variableSet
        for (let i = 0; i < splitEquation.length; i++) {
            if (!operators.includes(splitEquation[i])) {
                // If it's not an operator, evaluate the field
                splitEquation[i] = evaluateField(splitEquation[i], variableSet, dateCheck);
            }
        }
        const replacedEquation = splitEquation.join(' ');

        // Use eval to evaluate the replaced equation
        try {
            return eval(replacedEquation);
        } catch (error) {
            console.error(`Error evaluating equation: ${equation}\nReplacedEquation: ${replacedEquation}`, error);
            return false;
        }

    });
}

export function evaluateField(field, variableSet, dateCheck) {
    const keys = field.split('.');
    let value = variableSet;
    let parameter = null;

    for (let k of keys) {
        // Check for special function prefix
        if (k.startsWith('_')) {
            const [funcKey, funcArgs] = k.includes('{') ? k.split('{') : [k, null];
            const func = specialFunctions[funcKey];
            if (funcArgs) {
                parameter = funcArgs.replace(/[{}]/g, ''); // Extract argument value
            }

            return func ? func(value, parameter, dateCheck) : 0;
        } else {
            // Check if the key exists in the props, if not return the key itself
            if (value[k] !== undefined) {
                value = value[k];
            } else {
                return k;
            }
        }
    }

    return value;
}