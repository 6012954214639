import { React, useState, useContext } from "react";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

import Cookies from "js-cookie";

import FullPageScroll from "../../../../Components/fullPageScroll/FullPageScroll";
import Campaigns from "../../../../Components/campaigns/Campaigns";
import { MissionsTable } from "../../../Components/missionTable/missionsTable";
import { Header } from "../../../Components/header/header";
import { SideMenu } from "../../../Components/sideMenu/sideMenu";
import CampaignInfoTable from "./CampaignInfoTable";
import AddStudentTable from "./AddStudentTable";
import GradeTable from "./GradeTable";
import Overlay from "../../../../Components/overlay/Overlay";
import { InfoContext } from "../AdminCampaignsPage";

import styles from "../adminCampaignsPage.module.css";

const participatingTableSetup = [
  { accessorKey: "fname", header: "First Name" },
  { accessorKey: "lname", header: "Last Name" },
  { accessorKey: "grade", header: "Grade" },
  { accessorKey: "studentProgress", header: "Student Progress" },
];

const crossSchoolTableSetup = [
  { accessorKey: "schoolName", header: "School Name" },
  { accessorKey: "studentCount", header: "Students" },
  { accessorKey: "progress", header: "Progress" },
];

const DisplayCampaign = () => {
  const { currentCampaign } = useContext(InfoContext);
  const role = JSON.parse(Cookies.get("user")).role;
  const [overlayContent, setOverlayContent] = useState();
  const navigate = useNavigate();

  const displayAddStudentsTable = () => {
    setOverlayContent(<AddStudentTable campaignId={currentCampaign._id} />);
  };

  const addIcon = (
    <div className={styles.iconContainer}>
      <IconButton onClick={displayAddStudentsTable}>
        <AddIcon fontSize="medium" className={styles.addIcon} />
      </IconButton>
    </div>
  );

  const clickHandler = (id) => {
    navigate(`/user/${currentCampaign.db}_${id}`);
  };

  const section2 = (
    <>
      <div className={styles.tables}>
        <CampaignInfoTable
          campaignId={currentCampaign._id}
          campaignGoalType={currentCampaign.goalType}
          name={currentCampaign.name}
          startDate={currentCampaign.startDate}
          endDate={currentCampaign.endDate}
          progress={currentCampaign.progress}
          goalCompleted={currentCampaign.goalCompleted}
          role={role}
        />
        <GradeTable
          campaignId={currentCampaign._id}
          gradeTable={currentCampaign.gradeTable}
          role={role}
        />
        <MissionsTable
          title="Participating Students"
          tableSetup={participatingTableSetup}
          data={currentCampaign.participants}
          tooltip={role !== "parent" ? { value: addIcon } : null}
          clickHandler={clickHandler}
        />
        {currentCampaign.crossSchoolData && (
          <MissionsTable
            title="Participating Schools"
            tableSetup={crossSchoolTableSetup}
            data={currentCampaign.crossSchoolData}
          />
        )}
      </div>
    </>
  );

  const sections = [
    {
      value: <Campaigns role={role} campaign={currentCampaign} />,
      header: role !== "student" && <Header menu={<SideMenu />} />,
      dynamic: true,
    },
    { value: section2, scrollable: true },
  ];
  return (
    <>
      {overlayContent && (
        <Overlay
          content={overlayContent}
          closeHandler={() => setOverlayContent(null)}
        />
      )}
      <FullPageScroll sections={sections} />
    </>
  );
};

export default DisplayCampaign;
