import { useState } from "react";
import Cookies from "js-cookie";

import FormInput from "../../../../Components/FormInput";

import styles from "../userPage.module.scss";
import useHandleAddUserHook from "../hooks/UseHandleAddUserHook";
import { RenderableContent, SetState } from "../../../../types";
// Add to class future update
// import SelectInput from "../../../Components/selectInput/SelectInput";
// import SelectInputOption from "../../../Components/selectInput/components/SelectInputOption";
import { capitalizeWords } from "../../../../Util/utils";
import { validation } from "../../../../Util/Validation";
import ActionSuccessStatus from "../../../Components/actionSuccessStatus/ActionSuccessStatus";
import { TeacherClass, UserInfo } from "../types";

// Add to class future update
// type teacherClassObj = {
//   _id: string;
//   name: string;
//   studentIds: string[];
// };

type Props = {
    setOpenDialog: SetState<{ open: boolean; content: RenderableContent }>;
    // Add to class future update
    // teacherClasses: teacherClassObj[];
    setUsersInfo: SetState<UserInfo[]>;
    // Add to class future update
    // setTeacherClasses: SetState<TeacherClass[]>;
};

const NewUser = ({
    setOpenDialog,
    // Add to class future update
    // teacherClasses,
    setUsersInfo,
}: // Add to class future update
    // setTeacherClasses,
    Props) => {
    const handleAddUserHook = useHandleAddUserHook();
    // Add to class future update
    // const [teacherClassErr, setTeacherClassErr] = useState<boolean>(false);
    const [fname, setFname] = useState<string>("");
    const [lname, setLname] = useState<string>("");
    const [username, setUsername] = useState<string>("");
    const [parentEmail, setParentEmail] = useState<string>("");
    const [grade, setGrade] = useState<string>("");
    // Add to class future update
    // const [addToClass, setAddToClass] = useState<{ value: string; new: boolean }>(
    //   { value: "", new: false }
    // );

    const handleAddUser = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // Add to class future update
        // if (addToClass.value === "" || addToClass.value === "choose") {
        //   setTeacherClassErr(true);
        //   return;
        // }

        const userCookie = Cookies.get("user");
        const userObject = userCookie ? JSON.parse(userCookie) : null;
        let teacherUsername = userObject?.username ? userObject.username : "";
        const schoolName = userObject?.organization
            ? userObject.organization.replace(" ", "")
            : "school";
        const password =
            userObject?.organization.replace(/ /g, "").toLowerCase() ||
            "defaultPassword";

        setOpenDialog({
            open: true,
            content: <ActionSuccessStatus message={`Loading.... \n Please Wait`} />,
        });

        const content = await handleAddUserHook(
            capitalizeWords(fname),
            capitalizeWords(lname),
            username,
            schoolName,
            password,
            parentEmail,
            teacherUsername,
            // Add to class future update
            // addToClass,
            grade,
            setOpenDialog,
            setUsersInfo
            // Add to class future update
            // setTeacherClasses
        );
        if (!content) return;
        setOpenDialog({
            open: true,
            content: content,
        });
    };

    // Add to class future update
    // const selectInputOptions = [
    //   <SelectInputOption
    //     key={"choose"}
    //     option={{ name: "Select A Class", value: "choose" }}
    //   />,
    //   teacherClasses.map((teacherClass) => {
    //     return (
    //       <SelectInputOption
    //         key={teacherClass._id}
    //         option={{ name: teacherClass.name, value: teacherClass._id }}
    //       />
    //     );
    //   }),
    //   <SelectInputOption
    //     key={"newClass"}
    //     option={{ name: "New Class", value: "newInput" }}
    //   />,
    // ];

    return (
        <div className={styles.mainContainer}>
            <div className={`${styles.mainText} text-center`}>Add New Student</div>
            <form className={styles.addStudentForm} onSubmit={handleAddUser}>
                <div className={styles.inputRow}>
                    <FormInput
                        placeholder="First Name"
                        name="fname"
                        required
                        type="text"
                        value={fname}
                        onChangeHandler={(newValue) => setFname(newValue)}
                        pattern={validation.name.pattern}
                        message={validation.name.message}
                    />
                    <FormInput
                        placeholder="Last Name"
                        name="lname"
                        required
                        type="text"
                        value={lname}
                        onChangeHandler={(newValue) => setLname(newValue)}
                        autoFocus={false}
                        pattern={validation.name.pattern}
                        message={validation.name.message}
                    />
                </div>
                <div className={styles.inputRow}>
                    <FormInput
                        placeholder="Students Email (Optional)"
                        name="email"
                        type="text"
                        value={username}
                        onChangeHandler={(newValue) => setUsername(newValue)}
                        autoFocus={false}
                        required={false}
                        pattern={validation.email.pattern}
                        message={validation.email.message}
                    />
                    {/* Add to class future update
          <SelectInput
            options={selectInputOptions}
            setValue={setAddToClass}
            submitErrCheck={teacherClassErr}
          /> */}
                </div>
                <div className={styles.inputRow}>
                    <FormInput
                        placeholder="Grade"
                        name="grade"
                        required
                        type="text"
                        value={grade}
                        onChangeHandler={(newValue) => setGrade(newValue)}
                        autoFocus={false}
                        pattern={validation.grade.pattern}
                        message={validation.grade.message}
                    />
                    <FormInput
                        placeholder="Parents Email"
                        name="parentEmail"
                        required
                        type="text"
                        value={parentEmail}
                        onChangeHandler={(newValue) => setParentEmail(newValue)}
                        autoFocus={false}
                        pattern={validation.email.pattern}
                        message={validation.email.message}
                    />
                </div>
                <div className={styles.btnContainer}>
                    <button className={`${styles.submitBtn}`} type="submit">
                        Create Student
                    </button>
                </div>
            </form>
        </div>
    );
};

export default NewUser;
