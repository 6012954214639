import React, { useRef } from "react";

import PropTypes from "prop-types";

import cssModule from "./infoTable.module.scss";

import EditableField from "./components/EditableField";
import { formatDateString, isISODateString } from "../../../Util/utils";
import { useReactToPrint } from "react-to-print";
import PrintTemplate from "../../../Components/printTemplate/PrintTemplate";
import CustomBtn from "./components/CustomBtnField";

const InfoTable = ({
  tableTitle,
  tableInfo,
  onSaveHandler,
  route,
  handleRowClick,
  infoTableCustomClass,
  printable,
}) => {
  let value;
  const templateRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => templateRef.current,
  });

  const info = tableInfo.map((info) => {
    if (!info.value && info.value !== 0) return null;
    if (isISODateString(info.value)) {
      info.value = formatDateString(info.value);
    }
    if (!info.editable) {
      if (info.customBtn) {
        value = (
          <CustomBtn
            value={info.value}
            btnText={info.customBtn.btnText}
            action={info.customBtn.action}
          />
        );
      } else {
        value = info.value;
      }
    } else {
      value = (
        <EditableField
          originalValue={info.value}
          suffix={info.suffix}
          noneTableContingencies={info.noneTableContingencies}
          onSaveHandler={onSaveHandler}
          route={route}
          path={info.path}
          type={info.type}
        />
      );
    }
    if (!info.editable && info.suffix) value = `${value} ${info.suffix}`;

    return (
      <div
        className={cssModule.row}
        key={info.label}
        onClick={() => handleRowClick(info)}
      >
        <div className={cssModule.headerContainer}>
          <div className={cssModule.header}>{info.label}:</div>
        </div>
        <div className={cssModule.data}>
          <div className={cssModule.data}>{value}</div>
          {!info.editable && <div className={cssModule.spacer}></div>}
        </div>
      </div>
    );
  });

  const mainContent = (
    <div className={cssModule.info_table_container}>
      <div className={cssModule.table_title}>
        {tableTitle}{" "}
        {printable && (
          <div className={cssModule.printBtnContainer}>
            {" "}
            <button onClick={handlePrint} className={cssModule.printBtn}>
              Print Table
            </button>
          </div>
        )}
      </div>
      <div className={`${cssModule.info_table} ${infoTableCustomClass}`}>
        {info}
      </div>
    </div>
  );

  return (
    <>
      {mainContent}
      {printable && (
        <PrintTemplate ref={templateRef} contentToPrint={<>{mainContent}</>} />
      )}
    </>
  );
};

InfoTable.propTypes = {
  tableTitle: PropTypes.string,
  tableInfo: PropTypes.array.isRequired,
  onSaveHandler: PropTypes.func,
  route: PropTypes.string,
  handleRowClick: PropTypes.func,
  infoTableCustomClass: PropTypes.string,
  printable: PropTypes.bool,
  // customBtn: PropTypes.shape({
  //   btnText: PropTypes.string.isRequired,
  //   action: PropTypes.func.isRequired,
  // }),
};

InfoTable.defaultProps = {
  tableInfo: [],
  handleRowClick: (info) => {},
  infoTableCustomClass: "",
  printable: null,
};

export default InfoTable;
