import { React, useState, useEffect } from "react";
import Cookies from "js-cookie";

import ApiService from "../../../Services/apiService";
import UserPageTemplate from "../../Components/userPageTemplate/UserPageTemplate";
import DialogTemplate from "../../../Components/DialogTemplate";
import CarouselTest from "../../../Components/carousel/CarouselTest";
import CenterBox from "../../Components/centerBox/CenterBox";
import CarouselBlock from "./components/CarouselBlock";

import cssModule from "./achievementsPage.module.css";

const images = {
  ach_mobile_banner: require("../../../assets/images/userSite/achievements_banner.png"),
  coinBanner: require("../../../assets/images/userSite/coins_icon.png"),
  rankBanner: require("../../../assets/images/userSite/rank_icon.png"),
  streakBanner: require("../../../assets/images/userSite/streak_icon.png"),
  markMission: require("../../../assets/images/userSite/mark_missions.png"),
};

const AchievementsPage = () => {
  const apiService = new ApiService(true);
  const [stats, setStats] = useState({
    name: "DEMO",
    coins: -1,
    rank: -1,
    streak: -1,
    verified: false,
  });
  const [dialog, setDialog] = useState(null);
  // const [loadingDialogOpen,setLoadingDialogOpen] = useState(true);

  useEffect(() => {
    // setLoadingDialogOpen(true);
    const fetchData = async () => {
      const [up_to_date, cached_data] = [false, null]; //await getCached(cacheName);
      if (!up_to_date) {
        const [userResp, userError] = await apiService.get({
          path: "/get_achievements",
          dataType: "user",
        });
        if (!userError) {
          const stats = {
            name: userResp.data.name.split(" ")[0],
            coins: userResp.data.achievements.superMeCoins,
            rank: userResp.data.achievements.superMeRank,
            streak: userResp.data.achievements.superMeStreak,
            verified: userResp.data.verified,
          };

          setStats(stats);

          // const verify_notify = Cookies.get("verify-notify");
          // if (!stats.verified && !verify_notify) {
          //   Cookies.set("verify-notify", true);
          //   setDialog({
          //     mainText: "Welcome!",
          //     subText: "Please ask your parents to verify your account",
          //     duration: "1000ms",
          //     subTextClass: "mx-2",
          //     closeHandler: () => setDialog(null),
          //     button1Text: "Resend Email",
          //     onClickButton1: async () => {
          //       const [emailResp, emailError] = await apiService.post({
          //         path: "/reverify_email",
          //         dataType: "user",
          //       });

          //       if (emailResp) {
          //         setDialog({
          //           mainText: "Email Sent!",
          //           subText: "Please ask you parent to check thier email.",
          //           duration: "1000ms",
          //           subTextClass: "mx-2",
          //           closeHandler: () => setDialog(null),
          //         });
          //       } else {
          //         setDialog({
          //           mainText: "Uh Oh!",
          //           subText:
          //             "The email was not sent.\nPlease contact support for help",
          //           duration: "1000ms",
          //           subTextClass: "mx-2",
          //           closeHandler: () => setDialog(null),
          //         });
          //       }
          //     },
          //   });
          // }
        } else {
          console.log(
            `Error Retriving Mission Data:\n ${userError.toString()}`
          );
        }
      } else {
        if (cached_data) {
          setStats(cached_data.stats);
        }
      }

      // setLoadingDialogOpen(false);
    };

    fetchData();

    return () => {
      //handleLoadingDialogClose();
      // this now gets called when the component unmounts
    };
  }, []);

  const carouselItems = [
    <CarouselBlock
      bannerImg={images.coinBanner}
      title={"Coins"}
      statValue={stats.coins}
      button={{
        url: '/quests',
        text: 'Mark quests'
      }
      }
    />,
    <CarouselBlock
      bannerImg={images.rankBanner}
      title={"Rank"}
      statValue={stats.rank}
      button={{
        url: '/treasureRoad',
        text: 'Mark Game'
      }
      }
    />,
    <CarouselBlock
      bannerImg={images.streakBanner}
      title={"Streak"}
      statValue={stats.streak}
      button={{
        url: '/missions',
        text: 'Mark missions'
      }
      }
    />,
  ];

  const boxContent = (
    <>
      <div className={cssModule.statsContainer}>
        <div className={cssModule.desktop_stats}>{carouselItems}</div>
        <div className={cssModule.mobile_stats}>
          <CarouselTest items={carouselItems} startIndex={0} />
        </div>
      </div>
    </>
  );

  const mainContent = <CenterBox boxContent={boxContent} />;

  return (
    <>
      <UserPageTemplate
        pageContent={mainContent}
        footer
        footerClass={"scrollableFooter"}
      />
      {dialog && <DialogTemplate {...dialog} />}
    </>
  );
};

export default AchievementsPage;
