import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import FormInput from "../../../../Components/FormInput";

import styles from "../creatorPage.module.scss";
import { useEffect, useState } from "react";
import { SetState } from "../../../../types";
import { Data } from "../types";

type Props = {
  unselect: (src: string, index: number) => void;
  url: string;
  imgBank: any;
  selected: { [key: string]: number };
  data: Data;
  setData: SetState<Data>;
  nameKey: keyof Data;
  nameRequired: boolean;
};

const CurrrentSelectedImg = ({
  unselect,
  url,
  imgBank,
  selected,
  data,
  setData,
  nameKey,
  nameRequired,
}: Props) => {
  const [inputValue, setInputValue] = useState<string>("");

  const handleChange = (newValue: string) => {
    setInputValue(newValue);
    setData((prev) => {
      const newDataValue = [...prev[nameKey].img.value];
      for (let i = 0; i < newDataValue.length; i++) {
        if (newDataValue[i].url === url) {
          newDataValue[i].name = newValue;
          break;
        }
      }
      return {
        ...prev,
        [nameKey]: {
          ...prev[nameKey],
          img: {
            ...prev[nameKey].img,
            value: newDataValue,
          },
        },
      };
    });
  };

  useEffect(() => {
    const imageName = data[nameKey].img.value.find((v) => v.url === url);
    if (imageName?.name && imageName.name !== "") {
      setInputValue(imageName.name);
    }
  }, []);

  if (!imgBank.keys().includes(`./${url}`)) {
    console.log("Image not found");
    return;
  }

  return (
    <div style={{ width: "100%" }}>
      <IconButton
        className={styles.closeIcon}
        onClick={() => unselect(url, selected[url])}
      >
        <CloseIcon />
      </IconButton>
      <div className={styles.selectedImgWrapper}>
        <div className={styles.selectedImg}>
          <img className={styles.imgTag} src={imgBank(`./${url}`)} alt="" />
        </div>
      </div>

      {nameRequired && (
        <div className={styles.imgNameInput}>
          <FormInput
            type="text"
            value={inputValue}
            onChangeHandler={(newValue) => handleChange(newValue)}
            placeholder="Option Name"
            classes={styles.textColor}
            autoFocus={true}
          />
        </div>
      )}
    </div>
  );
};

export default CurrrentSelectedImg;
