import styles from "../infoTable.module.scss";

type Props = {
  value: string;
  btnText: string;
  action: () => void;
};
const CustomBtn = ({ value, btnText, action }: Props) => {
  return (
    <>
      <div className={styles.data}>{value}</div>
      <button className={styles.edit} onClick={() => action()}>
        {btnText}
      </button>{" "}
    </>
  );
};

export default CustomBtn;
