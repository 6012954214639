import { React } from 'react'
import { Link } from "react-router-dom";

import styles from './mainFooter.module.scss';
import home from "../../../assets/main_css/home.module.css"
import main_css from "../../../assets/main_css/main.module.css";

const images = {
	logo_icon: require("../../../assets/images/website/logo_icon.png"),
	phone: require("../../../assets/images/website/phone.png"),
	email: require("../../../assets/images/website/email.png"),
	social1: require("../../../assets/images/website/social1.png"),
	social2: require("../../../assets/images/website/social2.png"),
};

function MainFooter() {
	return (
		<footer className={styles.footer}>
			<div className={`${home.container} ${styles.footer_head}`}>
				<div className="row">
					<div className="col-lg-3 col-md-12 col-sm-12" style={{ alignContent: 'center', textAlign: 'center', }}>
						<img src={images["logo_icon"]} className="img-fluid" style={{ maxWidth: '100px', margin: 'auto' }} alt="" />
						<div className={main_css.themeFontFamily} style={{ color: 'white', fontSize: '20px', fontWeight: 'bold' }}>The Super Me Club</div>
					</div>
					<div className="col-lg-2 col-md-12 col-sm-12">
						<div className={home.footer_links}>
							<h5>Quick links</h5>
							<ul>
								<li><Link to="/bookdemo">Book a demo</Link></li>
								<li><Link to="/sign-up?_form=student">Sign up</Link></li>
								<li><Link to="/login">Login</Link></li>
								<li><Link to="/contactUs">Contact Us</Link></li>
							</ul>
						</div>
					</div>
					<div className="col-lg-3 col-md-12 col-sm-12">
						<div className={home.footer_links}>
							<h5>Contact</h5>
							<ul>
								<li><a href="tel:+1(424)231-4195">
									<div style={{
										display: 'flex',
										alignItems: 'center'
									}}>
										<img src={images["phone"]} className="img-fluid" alt="" />+1 (321) 325-0583
									</div>
								</a></li>
								<li><a href="mailto:engineering@thesupermeclub.com">
									<div style={{
										display: 'flex',
										alignItems: 'center'
									}}>
										<img src={images["email"]} className="img-fluid" alt="" />Support
									</div>
								</a></li>
							</ul>
						</div>
					</div>
					<div className="col-lg-4 col-md-12 col-sm-12">
						<div className={`${home.footer_links} ${home.footer_colum_padding}`}>
							<h5>Social</h5>
							<ul>
								<li><a href="https://api.whatsapp.com/send?phone=14242314195">
									<div style={{
										display: 'flex',
										alignItems: 'center'
									}}>
										<img src={images["social1"]} className="img-fluid" alt="" />WhatsApp
									</div>
								</a></li>
								<li><a href="https://youtu.be/0CYkU2aBqj8">
									<div style={{
										display: 'flex',
										alignItems: 'center'
									}}>
										<img src={images["social2"]} className="img-fluid" alt="" />Youtube
									</div>
								</a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div className={main_css.base_footer}>
				<div className={home.container}>
					<div className="row">
						<div className="col-lg-6 col-md-6 col-6">
							<p className={`${home.paragraph} m-0`} >Copyright © 2023 SuperMe. All rights reserved.</p>
						</div>
						<div className="col-lg-6 col-md-6 col-6">
							<ul><li><Link to="/privacy">Privacy Policy & Legal</Link></li>
								<li><Link to="/terms">Terms & Conditions</Link></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</footer>

	);
}

export default MainFooter;