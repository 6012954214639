import FormInput from "../../../../Components/FormInput";
import { SetState } from "../../../../types";

import styles from "../adminProfile.module.scss";

type Props = {
  currentPass: string;
  setCurrentPass: SetState<string>;
  newPassVali: Record<string, string>;
  newPass: string;
  setNewPass: SetState<string>;
  confirmPass: string;
  setConfirmPass: SetState<string>;
  handleChangePassword: () => void;
};

const ChangePass = ({
  currentPass,
  setCurrentPass,
  newPassVali,
  newPass,
  setNewPass,
  confirmPass,
  setConfirmPass,
  handleChangePassword,
}: Props) => {
  return (
    <div className="row justify-content-center align-items-center">
      <div className={` text-center ${styles.headerText}`}>Change Password</div>
      <div className={`tableBorder py-5 m-5 col-6 ${styles.border}`}>
        <form action="submit">
          <div className="text-center pb-4">
            <FormInput
              placeholder={"Old Password"}
              value={currentPass}
              type="password"
              onChangeHandler={setCurrentPass}
              autoFocus={false}
            />
          </div>
          <div className="text-center pb-4">
            <FormInput
              placeholder={"New Password"}
              type="password"
              pattern={newPassVali.pattern}
              message={newPassVali.message}
              onChangeHandler={setNewPass}
              value={newPass}
              autoFocus={false}
            />
          </div>{" "}
          <div className="text-center pb-4">
            <FormInput
              placeholder={"Confirm Password"}
              type="password"
              onChangeHandler={setConfirmPass}
              pattern={newPass}
              message={"You'r passwords do not match"}
              value={confirmPass}
              autoFocus={false}
            />
          </div>
          <div className="text-center">
            <button
              type="button"
              className={`btn ${styles.btn}`}
              onClick={handleChangePassword}
            >
              Change Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePass;
