import { useEffect, useState } from "react";

import ApiService from "../../../../Services/apiServiceT";
import InfoField from "./InfoField";

import styles from "../adminProfile.module.scss";

type Info = {
  id: string;
  label: string;
  value: string;
  editable: boolean;
};

type ProfileRespData = {
  profile: Info[];
};

const Profile = () => {
  const [info, setInfo] = useState<Info[]>([]);
  const apiService = new ApiService();

  useEffect(() => {
    const fetchData = async () => {
      const [profileDataResp, err] = await apiService.get({
        path: "/profile",
        dataType: "admin",
      });
      if (!profileDataResp?.data) return;
      const profileRespData = profileDataResp.data as ProfileRespData;
      setInfo(profileRespData.profile);
    };
    fetchData();
  }, []);
  const setInfoHelper = (index: number, newValue: string) => {
    let copyInfo = [...info];
    copyInfo[index].value = newValue;
    setInfo(copyInfo);
  };
  return (
    <>
      {" "}
      <div className={`text-center ${styles.headerText}`}>Profile</div>
      <div className={`tableBorder m-md-5 py-5 px-1 ${styles.border}`}>
        <div
          className="row align-items-center justify-content-evenly"
          style={{ height: "100%" }}
        >
          <div className="col-12">
            <div className="row p-2">
              <table className="table">
                <tbody>
                  {info.map((row, index) => (
                    <InfoField
                      key={row.id}
                      id={row.id}
                      label={row.label}
                      value={row.value}
                      infoSetter={(newValue) => setInfoHelper(index, newValue)}
                      editable={row.editable}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
