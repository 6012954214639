import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import AdminTemplate from "../../Components/adminTemplate/AdminTemplate";

import InfoTable from "../../Components/infoTable/InfoTable";
import { MissionsTable } from "../../Components/missionTable/missionsTable";
import { MissionVerificationLogic } from "../../Components/missionVerification/MissionVerificationLogic";

import Cookies from "js-cookie";

import ApiService from "../../../Services/apiService";
import useUpdateInfo from "../../Hooks/useUpdateInfo";
import useResetPassHook from "./hooks/useResetPassHook";
import Overlay from "../../../Components/overlay/Overlay";
import ActionSuccessStatus from "../../Components/actionSuccessStatus/ActionSuccessStatus";

const missionsTableSetup = [
  { accessorKey: "category", header: "Category" },
  { accessorKey: "task", header: "Task" },
  { accessorKey: "comDate", header: "Completed" },
];

const campaignsTableSetup = [
  { accessorKey: "label", header: "Name" },
  { accessorKey: "studentsProgress", header: "Students Progress" },
  { accessorKey: "gradeProgress", header: "Grade Progress" },
  { accessorKey: "totalProgress", header: "Total Progress" },
];

const learnTableSetup = [
  { accessorKey: "question", header: "Question" },
  { accessorKey: "answer", header: "Student's Answer" },
  // { accessorKey: "answer", header: "Correct Answer" },
  { accessorKey: "date", header: "Date" },
];

const UserInfoPage = () => {
  const [usersInfo, setUsersInfo] = useState({ totals: [] });
  const [openDialog, setOpenDialog] = useState();
  const apiService = new ApiService();
  const role = JSON.parse(Cookies.get("user")).role;
  const { id } = useParams();
  const updateInfo = useUpdateInfo();
  const restPassHook = useResetPassHook();

  const onSaveHandler = ({
    itemId = id,
    route,
    path,
    newValue,
    arrayFilters,
  }) => {
    console.log(route, itemId, path, newValue, arrayFilters);
    updateInfo(route, itemId, path, newValue, arrayFilters);
  };

  const onResetPassHandler = async () => {
    const studentId = id.split("_").at(-1);
    const db = id.replace(`_${studentId}`, "");
    
    setOpenDialog(
      <ActionSuccessStatus
        message={
          <>
            {" "}
            Loading..... <br /> Please Wait{" "}
          </>
        }
      />
    );
    const result = await restPassHook({ studentId, db });
    if (result === "success") {
      setOpenDialog(
        <ActionSuccessStatus
          message={
            <>
              {" "}
              Reset Successful <br /> Password has been reset to{" "}
              {db.replace(/ /g, "").toLowerCase()}{" "}
            </>
          }
        />
      );
    } else {
      setOpenDialog(
        <ActionSuccessStatus
          message={
            <>
              {" "}
              Reset Failed <br /> Please try again or contact support for help.
            </>
          }
        />
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [userInfoResp, err] = await apiService.get({
          path: "/get_one_by_id",
          dataType: "admin",
          data: {
            id: id,
          },
        });
        return userInfoResp;
      } catch (error) {
        console.log(error);
      }
    };
    fetchData().then((res) => {
      if (!res) return
      for (const info of res.data.studentInfo) {
        if (info.label === "Password") {
          info.customBtn.action = onResetPassHandler;
        }
      }
      if (res) setUsersInfo(res.data);
    });
  }, []);

  const missionVerificationHandler = async (action, ids) => {
    const res = await MissionVerificationLogic(action, ids);
    if (action === "Delete" && res === true) {
      setUsersInfo((prev) => {
        let missions = prev.missions;
        let carousel = prev.totals.carousel;
        let studentStats = prev.studentStats;
        let deductCategory = {};
        ids.forEach((id) => {
          missions = missions.filter((mission) => {
            if (mission.id !== id) {
              return true;
            } else {
              if (deductCategory[mission.category]) {
                deductCategory[mission.category] =
                  deductCategory[mission.category] + 1;
              } else {
                deductCategory[mission.category] = 1;
              }
              return false;
            }
          });
        });
        carousel.forEach((category) => {
          if (deductCategory[category.title]) {
            category.value = category.value - deductCategory[category.title];
          }
        });
        studentStats.forEach((stat) => {
          if (stat.label === "Super Me Coins") {
            stat.value = stat.value - ids.length;
          }
        });
        return {
          ...prev,
          missions: missions,
          totals: { ...prev.totals, carousel: carousel },
          studentStats: studentStats,
        };
      });
    } else if (action === "Verify" && res === true) {
      setUsersInfo((prev) => {
        let missions = prev.missions;
        ids.forEach((id) => {
          missions = missions.map((mission) => {
            if (mission.id === id) {
              mission.verified = true;
            }
            return mission;
          });
        });
        return {
          ...prev,
          missions: missions,
        };
      });
    }
  };
  const mainContent = (
    <>
      <InfoTable
        tableTitle={"Student Information"}
        tableInfo={usersInfo.studentInfo}
      />
      <InfoTable
        tableTitle={"Student Stats"}
        tableInfo={usersInfo.studentStats}
        onSaveHandler={onSaveHandler}
        route={"/update_student_stats"}
      />
      <MissionsTable
        title={"Completed Missions"}
        tableSetup={missionsTableSetup}
        data={usersInfo.missions}
        verify
        buttonHandler={(action, ids) => {
          missionVerificationHandler(action, ids);
        }}
      />
      <MissionsTable
        title={"Campaigns"}
        tableSetup={campaignsTableSetup}
        data={usersInfo.campaigns}
        editable={role !== "parent"}
        route={"/update_campaign_stats"}
        onSaveHandler={onSaveHandler}
      />
      <MissionsTable
        title={"Completed Learn Questions"}
        tableSetup={learnTableSetup}
        data={usersInfo.learn}
      />
    </>
  );

  return (
    <>
      {openDialog && (
        <Overlay
          closeHandler={() => setOpenDialog(null)}
          content={openDialog}
        />
      )}
      <AdminTemplate
        carousel={usersInfo.totals.carousel}
        pageContent={mainContent}
      />
    </>
  );
};

export default UserInfoPage;
