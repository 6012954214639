export const defaultOptionImages = [
    "1_cups_water.png",
    "2_cups_water.png",
    "3_cups_water.png",
    "4_cups_water.png",
    "5_cups_water.png",
    "6_cups_water.png",
    "7_cups_water.png",
    "8_cups_water.png",
    "11_hours_icon.png",
    "8_hours_icon.png",
    "9_hours_icon.png",
    "10_hours_icon.png",
    "running_icon.png",
    "skipping_icon.png",
    "sports_icon.png",
    "veg_icon.png",
    "vegetable.png",
    "candles_icon.png",
    "cycling_icon.png",
    "dancing_icon.png",
    "fruit.png",
    "fruit_icon.png",
    "golf_icon.png",
    "healthy_fats_icon.png",
    "karate_icon.png",
    "pingpong_icon.png",
    "protein.png",
    "protein_icon.png"
]