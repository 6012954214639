import { useEffect, useState } from "react";
import FormInput from "../../../../Components/FormInput";
import { Data } from "../types";
import styles from "../creatorPage.module.scss";
import CreatorDialogTemplate from "./CreatorDialogTemplate";
import useUpdateData from "../hooks/useUpdateData";
import { RenderableContent } from "../../../../types";

type Props = {
  data: Data;
  setData: React.Dispatch<React.SetStateAction<Data>>;
};

const TaskStep = ({ data, setData }: Props): RenderableContent => {
  const updateData = useUpdateData();
  const [inputOne, setInputOne] = useState<string>("");
  const [inputTwo, setInputTwo] = useState<string>("");
  let firstRender: boolean = false;

  useEffect(() => {
    firstRender = true;
    if (data.task.value[0] === "") return;
    const stringValue = data.task.value.toString();
    setInputOne(stringValue.split(" ___ ")[0]);
    setInputTwo(
      stringValue.split(" ___ ")[1] ? stringValue.split(" ___ ")[1] : " "
    );
  }, [data]);

  useEffect(() => {
    if ((!inputOne && !inputTwo && data.task.value[0] === "") || firstRender) {
      return;
    } else if (
      (!inputOne && !inputTwo) ||
      (inputOne === "" && inputTwo === "")
    ) {
      updateData(data, "", "task", "", setData, [{ name: "", url: "" }]);
    } else if (!inputTwo || inputTwo === "" || inputTwo === " ") {
      updateData(data, `${inputOne}`, "task", "", setData, [
        { name: "", url: "" },
      ]);
    } else {
      updateData(data, `${inputOne} ___ ${inputTwo}`, "task", "", setData, [
        { name: "", url: "" },
      ]);
    }
  }, [inputOne, inputTwo]);

  const inputFields = (
    <div className={`${styles.taskInputField} ${data.task.err}`}>
      <FormInput
        type="text"
        value={inputOne ? inputOne : ""}
        placeholder="e.g. I drank "
        onChangeHandler={(v: string) => setInputOne(v)}
        required={false}
        autoFocus={true}
      />
      <div className={styles.taskUnderscore}> ___ </div>
      <FormInput
        type="text"
        value={inputTwo ? inputTwo : ""}
        placeholder=" cups of water today!"
        onChangeHandler={(v: string) => setInputTwo(v)}
        required={false}
        autoFocus={false}
      />
    </div>
  );

  const subText =
    "e.g. I drank _ cups of water today! The student to fill in with the options that you will set in the next step";

  return (
    <CreatorDialogTemplate
      name="task"
      data={data}
      setData={setData}
      mainText="Specify A Task"
      subText={subText}
      customFields={inputFields}
    />
  );
};

export default TaskStep;
