import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import ApiService from "../../../Services/apiService";
import UserNavBar from "../../Components/userNavbar/UserNavBar";
import CompletedDialog from "./components/completedDialog";
import ChooseDialog from "./components/chooseDialog";
import DialogTemplate from "../../../Components/DialogTemplate";

import { HorizontalScroll } from "../../../AdminPages/Components/horizontalScroll/horizontalScroll";

import { arrayToDictionary, mapRange } from "../../../Util/utils";
// import "./missions.scss";
import missionsCSS from "./missionsPage.module.scss";
import useMaxedOutDialog from "./hooks/useMaxedOutDialog";

const gameboardImages = require.context("../../../assets/images/userSite/gameboard", true);


const dataCreator = (mission) => {
  return {
    category: mission.category,
    task: mission.task,
    comDate: new Date(mission.comDate),
    rewardType: mission.rewardType,
    rewardAmount: mission.rewardAmount,
    image: mission.image,
    missionRefId: mission.missionRefId,
    teachersBonus: mission.teachersBonus,
    option: mission.option
  };
};


const MissionsPage = () => {
  const maxedOutDialog = useMaxedOutDialog()
  const navigate = useNavigate();
  const apiService = new ApiService(true);

  const [comDialog, setcomDialog] = useState({
    open: false,
    data: "",
  });
  const [choDialog, setchoDialog] = useState({
    open: false,
    data: "",
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const [comMissions, setComMissions] = useState([]);
  const [missionMaxed, setMissionMaxed] = useState({
    Nutrition: false,
    Hydration: false,
    "Physical Activity": false,
    Sleep: false,
    Gratitude: false,
    Kindness: false,
  });
  const [missions, setMissions] = useState({});
  const [gameboard, setGameboard] = useState({
    image: "",
    character: "",
    tilePositions: [],
    levelPositions: []
  });
  const [gameboardPos, setGameboardPos] = useState(0);
  const [campaignId, setCampaignId] = useState("");

  const [characterPosition, setCharacterPosition] = useState({ top: '66%', left: '60%', scale: 1 });

  const [redirect, setRedirect] = useState(false);
  const [dialog, setDialog] = useState(null);
  const [lockMissions, setLockMissions] = useState(false);

  useEffect(() => {
    // setLoadingDialogOpen(true);
    const fetchData = async () => {
      const [authResp, error] = await apiService.get({
        path: "/checkAuth",
        dataType: "authentication",
      });
      if (authResp.data === false) setRedirect(true);

      const [up_to_date, cached_data] = [false, null];
      if (!up_to_date) {
        const [userResp, userError] = await apiService.get({
          path: "/get_missions",
          dataType: "user",
          data: {
            role: "student",
            gameboardPos: -1,
          },
        });

        if (!userError) {
          let tempComMissions = [];
          setGameboardPos(userResp.data.gameboardPos);
          setCampaignId(userResp.data.campaignId);

          let currentGameboardMissions = userResp.data.missionsCompleted ?? [];
          for (let data of currentGameboardMissions) {
            tempComMissions.push(dataCreator(data));
          }

          let missions = arrayToDictionary(userResp.data.missions, '_id');

          setGameboard({
            character: userResp.data.gameboard?.image?.split('.png')[0] + '_character.png', // To handle schools that did not add character to DB
            ...userResp.data.gameboard,
          });
          setMissions(missions);
          setComMissions(tempComMissions);

          const yOffset = parseFloat((mapRange(tempComMissions.length, 0, Object.keys(userResp.data.gameboard?.tilePositions).length, 12, 16)).toFixed(0));
          const xOffset = parseFloat((mapRange(tempComMissions.length, 0, Object.keys(userResp.data.gameboard?.tilePositions).length, 0, 2)).toFixed(0));

          setCharacterPosition({
            top: tempComMissions.length ? `${parseFloat((userResp.data.gameboard?.tilePositions[tempComMissions.length - 1][0])) - yOffset}%` : '66%',
            left: tempComMissions.length ? `${parseFloat((userResp.data.gameboard?.tilePositions[tempComMissions.length - 1][1])) - xOffset}%` : '60%',
            scale: tempComMissions.length ? parseFloat(mapRange(tempComMissions.length, 0, Object.keys(userResp.data.gameboard?.tilePositions).length, 1.0, 0.60).toFixed(2)) : 1,
          })
        } else {
          console.log(
            `Error Retrieving Mission Data:\n ${userError.toString()}`
          );
        }
      } else {
        if (cached_data) {
          setComMissions(cached_data.currentGameboardMissions);
        }
      }

      // setLoadingDialogOpen(false);
    };

    fetchData();

    return () => {
      // this now gets called when the component unmounts
    };
  }, [gameboardPos]);

  useEffect(() => {
    const now = new Date();
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    let missionCount = {};
    let tempmissionMaxed = { ...missionMaxed };

    comMissions.forEach((mission) => {
      const diffDays = Math.round(
        Math.abs(
          now.setHours(0, 0, 0, 0) - mission.comDate.setHours(0, 0, 0, 0)
        ) / oneDay
      );

      if (missions.hasOwnProperty(mission.missionRefId) && diffDays < missions[mission.missionRefId].maxAllowed.period) {
        if (mission.teachersBonus) {
          if (!missionCount[mission.missionRefId]) {
            missionCount[mission.missionRefId] = {}
          }
          missionCount[mission.missionRefId][mission.option] = missionCount[mission.missionRefId][mission.option] ? missionCount[mission.missionRefId][mission.option] + 1 : 1
        } else {
          missionCount[mission.missionRefId] = missionCount[mission.missionRefId] ? missionCount[mission.missionRefId] + 1 : 1;
        }
      }
    });

    Object.keys(missionCount).map((key) => {

      if (typeof missionCount[key] === "object") {
        const missionCountTeacherBonusKeys = Object.keys(missionCount[key])
        missionCountTeacherBonusKeys.forEach((option) => {
          if (!tempmissionMaxed[key]) {
            tempmissionMaxed[key] = {}
          }

          if (missions.hasOwnProperty(key) && missionCount[key][option] >= missions[key].maxAllowed.amount) {
            return tempmissionMaxed[key][option] = true
          } else {
            return tempmissionMaxed[key][option] = false
          }
        })

        return tempmissionMaxed
      } else {
        if (missions.hasOwnProperty(key) && missionCount[key] >= missions[key].maxAllowed.amount) {
          return tempmissionMaxed[key] = true;
        } else return tempmissionMaxed[key] = false
      }
    });

    setMissionMaxed(tempmissionMaxed);
  }, [comMissions]);


  const handleOpen = (dialog, data, x, y, width, height) => {
    if (dialog === "completed") {
      setcomDialog({
        open: true,
        data: data,
      });
    } else if (dialog === "choose") {
      setchoDialog({
        open: true,
        data: data,
        x: x,
        y: y,
        width: width,
        height: height,
      });
    }
  };

  const moveCharacter = () => {
    const numberOfCompletedMissions = (comMissions.length + 1 >= gameboard.tilePositions.length) ? comMissions.length - 1 : comMissions.length;
    const yOffset = parseFloat(mapRange(numberOfCompletedMissions, 0, gameboard.tilePositions.length, 12, 16).toFixed(0));
    const xOffset = parseFloat(mapRange(numberOfCompletedMissions, 0, gameboard.tilePositions.length, 0, 2).toFixed(0));

    const tilePositionY = gameboard.tilePositions[numberOfCompletedMissions]?.[0];
    const tilePositionX = gameboard.tilePositions[numberOfCompletedMissions]?.[1];

    setCharacterPosition(prev => ({
      top: `${(parseFloat(tilePositionY)) - yOffset}%`,
      left: `${(parseFloat(tilePositionX)) - xOffset}%`,
      scale: parseFloat(mapRange(numberOfCompletedMissions, 0, gameboard.tilePositions.length, 1.0, 0.60).toFixed(2)),
    }));
  };

  const handleClose = (dialog) => {
    if (dialog === "completed")
      setcomDialog({
        open: false,
        data: "",
      });
    else if (dialog === "choose") {
      setchoDialog({
        open: false,
        data: "",
      });
    }
  };

  const handleCompleted = async (top, left, data) => {
    let newData = {
      mission: { ...data, verified: false },
      gameboardPos: gameboardPos,
      campaignId: campaignId,
      achievements: {},
    };

    //check if user finished a level or gameboard
    if (comMissions.length + 1 >= gameboard.tilePositions.length) {
      newData.achievements.superMeRank = 1;
    }

    //check if user should update streak
    if (
      comMissions.length - 1 > 0 &&
      Math.round(
        Math.abs(
          new Date(data.comDate).setHours(0, 0, 0, 0) -
          comMissions[comMissions.length - 1].comDate.setHours(0, 0, 0, 0)
        ) /
        (24 * 60 * 60 * 1000)
      ) === 1
    ) {
      newData.achievements.superMeStreak = 1;
    }

    const [mcResp, mcError] = await apiService.patch({
      path: "/add_mission",
      dataType: "user",
      data: newData,
    });

    if (!mcError) {
      playMcAnimation(top, left, data);
    } else {
      handleClose('choose');
      setDialog({
        open: true,
        mainText: "Something went wrong!",
        subText:
          "Please try again!\nIf this issue continues please reach out to Support",
        duration: "1000ms",
        closeHandler: () => setDialog(null),
      });
    }

    //Check if user completed the current gameboard.
    if (newData.achievements.superMeRank == 1) {
      setTimeout(() => {
        setDialog({
          open: true,
          mainText: "Congratulations!",
          subText: "You have completed all the missions on this gameboard!",
          duration: "1000ms",
          button1Text: "Continue To The Next Gameboard",
          onClickButton1: () => {
            setGameboardPos((prevGameboardPos) => {
              return prevGameboardPos + 1;
            });
            setDialog(null);

          },
        });
      }, 4000);
    }
  };

  const playMcAnimation = (top, left, data) => {
    setLockMissions(true);

    setchoDialog({
      open: false,
      data: "",
    });
    const img = document.querySelector('img[id="anima"]');
    img.style.top = `${top}px`;
    img.style.left = `${left}px`;
    img.src = gameboardImages(`./categories/${data["image"]}`);
    img.style.display = "block";
    img.style.width = `${choDialog.width}px`;
    img.style.height = `${choDialog.height}px`;
    img.style.zIndex = 99999;

    setTimeout(() => {
      //Move Coin to center of screen and scale up
      img.style.left = "50%";
      img.style.top = "50%";
      img.style.transform = "scale(3)";

      // Move the image to the desired position
      setTimeout(() => {
        img.style.top = `${gameboard.tilePositions[comMissions.length][0]}`;
        img.style.left = `${gameboard.tilePositions[comMissions.length][1]}`;
        img.style.transform = "scale(0)";

        // img.style.width = `${60 / aspectRatio}`;
        // img.style.height = `60px`;

        setTimeout(() => {
          //Hide image
          img.style.display = "none";
          img.style.transform = "scale(1)";
          setComMissions((prev) => prev.concat(dataCreator(data)));
          setLockMissions(false);
          moveCharacter();
        }, 1500);
      }, 2100);
    }, 100);
  };

  const missionTasks = Object.keys(missions).map((key) => (
    <div className={missionsCSS.chmission_img_container}>
      <img
        className={`${missionsCSS.chmission_img} ${missionsCSS.imageRowImg}`}
        src={gameboardImages(`./categories/${missions[key].image}`)}
        alt={key}
        key={key}
        style={{ filter: missionMaxed[key] === true ? "grayscale(100%)" : "none" }}
        onClick={(e) => {
          if (!missionMaxed[key] || typeof missionMaxed[key] === "object") {
            const rect = e.target.getBoundingClientRect();
            handleOpen(
              "choose",
              missions[key],
              `${rect.x}`,
              `${rect.y}`,
              `${rect.width}`,
              `${rect.height}`,
            );
          } else {
            maxedOutDialog(setDialog, missions[key].category)
          }
        }}
      />
      <p style={{ color: 'white', paddingTop: '8px' }}>{missions[key].category}</p>
    </div>
  ));

  const missionTasks_new = Object.keys(missions).map((key) => {
    return (
      () => {
        return (
          <div style={{ height: '100%', textAlign: 'center' }}>
            <img
              className={`${missionsCSS.chmission_img} ${missionsCSS.imageRowImg}`}
              src={gameboardImages(`./categories/${missions[key].image}`)}
              alt={key}
              key={key}
              style={{ filter: missionMaxed[key] ? "grayscale(100%)" : "none" }}
              onClick={(e) => {
                if (!missionMaxed[key]) {
                  const rect = e.target.getBoundingClientRect();
                  handleOpen(
                    "choose",
                    missions[key],
                    `${rect.x}`,
                    `${rect.y}`,
                    `${rect.width}`,
                    `${rect.height}`,
                  );
                } else {
                  setDialog({
                    open: true,
                    classes: "mission_modal_container",
                    mainText: `${missions[key].category} Missions Maxed Out!`,
                    mainTextClass: "mission_modal_main_text",
                    subText:
                      "You completed this mission to the max!\nCome back tommorrow to do it again!",
                    subTextClass: "mission_modal_sub_text",
                    duration: "1000ms",
                    closeHandler: () => setDialog(null),
                  });
                }
              }}
            />
            <p style={{ color: 'white', paddingTop: '8px' }}>{key}</p>
          </div>);
      }
    );
  });

  const mainsection = (
    <div className={missionsCSS.mainsection} style={{ backgroundImage: gameboard.image && `url(${gameboardImages(`./boards/${gameboard.image}`)})` }}>
      {comMissions.map((mission, index) => (
        <img
          className={missionsCSS.comission_img}
          style={{ top: gameboard.tilePositions[index][0], left: gameboard.tilePositions[index][1], scale: gameboard.tilePositions[index].length > 2 ? gameboard.tilePositions[index][2] : '1', zIndex: comMissions.length - index, }}
          src={mission.image && gameboardImages(`./categories/${mission.image}`)}
          alt=""
          key={index}
          onClick={() => {
            handleOpen("completed", mission);
          }}
        />
      ))}
      <img
        alt="character"
        className={missionsCSS.character}
        src={gameboard.character && gameboardImages(`./boards/${gameboard.character}`)}
        style={{ ...characterPosition, scale: `${characterPosition.scale}` }}
      />
      <div className={missionsCSS.imageRow} style={{ pointerEvents: lockMissions ? "none" : "auto" }}>
        {/* {missionTasks} */}
        {/* <CarouselTest items={missionTasks} startIndex={0} /> */}
        {/* <Scrollable direction="horizontal" components={missionTasks_new} /> */}
        <HorizontalScroll>
          <div className={missionsCSS.imageRowScroll}>
            {missionTasks}
          </div>
        </HorizontalScroll>
      </div>

      <CompletedDialog
        open={comDialog["open"]}
        handleClose={handleClose}
        mission={comDialog["data"]}
      />

      <ChooseDialog
        open={choDialog["open"]}
        handleClose={handleClose}
        task={choDialog["data"]}
        handleCompleted={handleCompleted}
        missionMaxed={missionMaxed}
        setDialog={setDialog}
        x={choDialog["x"]}
        y={choDialog["y"]}
      />
    </div>
  );

  const mcAnimSection = (
    <img
      className={missionsCSS.chmission_img}
      id="anima"
      src={""}
      alt="mcAnima"
      style={{
        display: "none",
        position: "absolute",
        width: "100px",
        transition: "all 2s",
        scale: "1",
        // left: mcAnimation['leftPos'],
        // top: mcAnimation['topPos'],
      }}
    />
  );

  const lcAnimSection = (
    <div
      style={{
        display: "none",
        position: "absolute",
        transition: "all 2s",
      }}
    ></div>
  );

  if (redirect) {
    navigate("/login");
  } else {
    return (
      <>
        {dialog && <DialogTemplate {...dialog} />}

        <UserNavBar />
        <div className={missionsCSS.missions_container}>
          {mainsection}
          {mcAnimSection}
          {lcAnimSection}
        </div>
      </>
    );
  }
};

export default MissionsPage;
