import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

// Define the types for CustomTabPanel props
interface CustomTabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: CustomTabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// Accessibility props function
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// Define the types for MuiTabs props
interface MuiTabsProps {
  tabs: string[];
  items: React.ReactNode[];
  tabsContainerSx?: object; // For custom styles like padding
  centerTabs?: boolean; // To center the tabs
}

const MuiTabs: React.FC<MuiTabsProps> = ({
  tabs,
  items,
  tabsContainerSx = {},
  centerTabs = false,
}) => {
  const [value, setValue] = React.useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          justifyContent: centerTabs ? "center" : "flex-start",
          ...tabsContainerSx, // Custom styles for the tabs container
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab} {...a11yProps(index)} />
          ))}
        </Tabs>
      </Box>
      {items.map((item, index) => (
        <CustomTabPanel value={value} index={index} key={index}>
          {item}
        </CustomTabPanel>
      ))}
    </Box>
  );
};

export default MuiTabs;
