import { useCallback } from "react";
import ApiService from "../../../../Services/apiServiceT";

type ResetPassHookProps = {
  studentId: string;
  db: string;
};

type Resp = {
  status: number;
};

const useResetPassHook = () => {
  const apiService = new ApiService();
  const resetPassHook = useCallback(async ({ studentId, db }: ResetPassHookProps) => {
    try {
      const [resp, err] = await apiService.patch({
        path: "/reset_students_pass",
        dataType: "admin",
        data: { studentId, db },
      });
      if (resp) {
        const resetResp = resp as Resp;
        if (resetResp.status === 200) {
          return "success";
        } else {
          return "failed";
        }
      } else {
        console.log(err);
        return "failed";
      }
    } catch (error) {
      console.log(error);
      return "failed";
    }
  }, []);
  return resetPassHook;
};

export default useResetPassHook;
